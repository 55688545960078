import * as React from 'react'
import { Flex } from 'grid-styled'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { Helmet } from 'react-helmet'
import Hero from '../../components/Hero'
import Button from '../../components/Button'
import BackgroundSection from '../../components/BackgroundSection'
import MainLayout from '../../components/Layout'
import { AboutContact } from '..'
import {
  BeforeAfter,
  StyledTabs,
  StyledTabList,
  StyledTab,
  Content,
} from '../../components/procedures'
import { TabPanel } from 'react-tabs'

const content = {
  backgroundTitle: 'Other Breast Options',
  subtitle: `Trusted, Experienced & Ethical. Boutique plastic surgery on Sydney's North Shore.`,
  title: 'Breast Augmentation',
  otherOptions: [
    {
      text: 'Breast Augmentation',
      to: '#',
    },
    {
      text: 'Breast Reconstruction',
      to: '/procedures/breast-reconstruction',
    },
    {
      text: 'Breast Reduction',
      to: '/procedures/breast-reduction',
    },
  ],
}

export default ({ data }) => (
  <MainLayout header="light">
    <Helmet>
      <title>
        Breast Enlargement / Augmentation in Sydney - Dr. Graham Sellars
      </title>
      <meta
        name="description"
        content="Breast Enlargement or Augmentation is one of the most common surgical procedures performed by Dr. Sellars, having many years of experience with this surgery."
      />
    </Helmet>

    <Hero bgImage={data.womanWaterfall.childImageSharp} height="600px">
      <h1>{content.title}</h1>
      <p>{content.subtitle}</p>
      <Flex>
        <Button color="#fff" small={true} outline={true}>
          Learn more
        </Button>
      </Flex>
    </Hero>

    <Content>
      <h2>Breast Augmentation & Enlargement</h2>
      <span>
        Breast Enlargement or Augmentation is one of the most common surgical
        procedures performed by Dr. Sellars. He has had over 20 years of
        experience performing this surgery.
      </span>

      <h4>About Breast Augmentation</h4>

      <p>
        Breast Augmentation is a procedure designed to enlarge your breasts and making them more in
        proportion with the rest of your body. Breast Augmentation can also be
        used to correct differences in the size of your breasts or sagging
        breasts (ptosis) which can occur after pregnancy and breast-feeding.
      </p>

      <h4>Things to consider before Breast Augmentation</h4>

      <p>
        There are many choices and options in Breast Augmentation, which require
        careful planning to individualise which are the best options for you.
        All of these choices have advantages and disadvantages, which you should
        understand before you make a decision with your Surgeon.
      </p>

      <p>
        Breast Implants will make your breasts larger, but the underlying shape
        of your breasts will not change, and some differences in the size and
        shape of your breasts may even be more obvious after the surgery.
      </p>

      <h4>Why consider Breast Augmentation?</h4>

      <p>
        Breast Enlargement surgery is chosen by women of all ages from all walks
        of life for a variety of reasons. Many women, after raising a family,
        want to restore what time, gravity and child rearing has taken away.
        Women are primarily motivated to choose this surgery for their own
        personal satisfaction and body image, rather than to please a partner.
      </p>

      <p>
        Your decision to undergo Breast Augmentation will be based on your own
        needs, desires and expectations. However, before you undergo Breast
        Augmentation, you need to be well informed about the procedure, the
        different choices involved, the results you can expect, and all the
        potential risks and complications that can occur.
      </p>
      <Link to="/contact/">
        <Button inverted={true}>Contact us today!</Button>
      </Link>
    </Content>

    <BackgroundSection
      image={data.otherBreast.childImageSharp}
      title={content.backgroundTitle}
      height="500px"
      darkness="0.4"
    >
      <p style={{ fontSize: 18, margin: '-1rem 0 2rem' }}>
        Browse Dr. Graham Sellars' full range of surgical and non-surgical
        procedures.
      </p>
      {content.otherOptions.map(({ to, text }) => (
        <Flex mr={2} mb={3} style={{ float: 'left' }}>
          <Link to={to}>
            <Button small={true} color="#fff" outline={true}>
              {text}
            </Button>
          </Link>
        </Flex>
      ))}
    </BackgroundSection>

    <StyledTabs style={{ marginTop: 50 }}>
      <h2>Information about {content.title}</h2>
      <StyledTabList style={{ flexWrap: 'wrap', justifyContent: 'center' }}>
        <StyledTab style={{ margin: '0.5rem' }}>
          Silicone vs. Saline Implants
        </StyledTab>
        <StyledTab style={{ margin: '0.5rem' }}>
          Surgery &amp; Placement options
        </StyledTab>
        <StyledTab style={{ margin: '0.5rem' }}>Post operative care</StyledTab>
        <StyledTab style={{ margin: '0.5rem' }}>Breast feeding</StyledTab>
        <StyledTab style={{ margin: '0.5rem' }}>
          Breast cancer screening
        </StyledTab>
        <StyledTab style={{ margin: '0.5rem' }}>
          Risks of Surgery
        </StyledTab>
      </StyledTabList>

      <TabPanel>
        <h4>About Saline Breast Implants</h4>

        <p>
          The main reason why many patients opt for Saline Breast Implants is
          that if the Implant were to rupture the breast “deflates” and the
          saline is simply absorbed by the body. However, Saline Breast Implants
          tend to demonstrate wrinkles, which can be felt and are sometimes
          visible. To avoid this, Saline Breast Implants can be slightly
          overfilled, but this leads to a more spherical appearance with
          increased fullness in the upper part of the breast and a firmer feel
          to the Breast Implants. Saline Breast Implants have a rupture rate of
          1-3% per year, which requires another operation to correct.
        </p>

        <h4>About Silicone Breast Implants</h4>

        <p>
          Today modern Silicone Breast Implants are substantially different from
          the old type used in the 1970s and 80s. The casing of the Silicone
          Breast Implant is much thicker. Modern cohesive Silicone Gel Breast
          Implants contain a gel that is similar in consistency to a firm set
          jelly, which retains its shape and does not run like a liquid, as the
          old silicone gel did.
        </p>

        <p>
          Another advantage of the Silicone Gel Breast Implants is that they are
          made in a variety of round and tear-drop shapes, which means it is
          easier to choose a Breast Implant to achieve the breast shape that you
          desire.
        </p>

        <div>
          <Img
            style={{
              width: '200px',
              margin: '20px  0',
              float: 'left',
            }}
            fluid={data.implantType.childImageSharp.fluid}
          />
          <Img
            style={{ width: '200px', margin: '20px', float: 'left' }}
            fluid={data.implantType2.childImageSharp.fluid}
          />
        </div>

        <h4>Implant shapes – low profile, medium profile, high profile</h4>

        <p>
          Like all foreign materials, Silicone is walled off by the body with a
          thin, inelastic lining or “capsule”. In both cases the capsule can
          undergo capsular contracture where the capsule surrounding the Breast
          Implant tightens, becomes hard and occasionally tender.
        </p>

        <p>
          All Silicone Gel Breast Implants will develop a very fine layer of
          Silicone, which osmoses through the elastomer shell over many years.
          This is known as gel bleed and has been reduced in some of the newer
          Breast Implants with advanced layering of the elastomer shell. If
          Silicone Breast Implants do rupture, the capsule will initially
          confine the gel. Free gel may precipitate capsular contracture. If
          unchecked, Silicone gel can extravasate or leak outside the capsule,
          known as an extra capsular rupture and results in localised granuloma
          (benign lumps) around the edge of the capsule.
        </p>

        <p>
          The granulomas may be tender. If ignored the older, less cohesive gels
          can spread towards the axilla (armpit). This certainly has been the
          down side of Gel Implants in the past and is unusual with the newer,
          cohesive Gel Breast Implants.
        </p>

        {/* <Img fluid={data.implantProfile.childImageSharp.fluid} /> */}
      </TabPanel>

      <TabPanel>
        <h4>
          Breast Implants are placed either behind the breast tissue or under
          the pectoralis muscle on the chest wall.
        </h4>

        <Img
          fluid={data.implant.childImageSharp.fluid}
          style={{ marginBottom: 20 }}
        />

        <ul>
          <li>a &amp; b. Anatomy of the Breast</li>
          <li>c. Implant placed “Submuscular” (under the muscle)</li>
          <li>d. Implant placed “Subglandular” (under the glandular tissue)</li>
          <li>
            e. Implant placed “Dual Plane” (partly under the muscle and partly
            under the glandular tissue)
          </li>
          <li>
            f. Implant placed “Subfascial” (placed under the fascial layer over
            the muscle)
          </li>
        </ul>

        <p>
          Breast Implants that are placed underneath the muscle (submuscular),
          have a more natural shape in thin women, a lower chance of capsular
          contracture (hardening) of the implant and it is easier for
          mammography.
        </p>

        <p>
          Breast Implants that are placed underneath the breast gland
          (subglandular), look natural in women with thicker tissue over the
          muscle, age more naturally in women with a good amount of (pre-op)
          breast volume, and have correct sagging of the breast.
        </p>

        <p>
          There is also a choice of incision: In the crease under the breast;
          Under the areola around the nipple; In the armpit; All of these have
          advantages and disadvantages which you should discuss at your
          consultation with Dr Sellars.
        </p>

        <h4>Breast Augmentation Surgery</h4>
        <p>
          Breast Augmentation surgery requires a general Anaesthetic, with the
          operation taking 1-2 hours, and is usually performed as a day-stay or
          overnight-stay procedure.
        </p>

        <p>
          Following the surgery it is important to have restricted light
          activities, with no lifting or heavy duties for 7-10 days. Most
          patients may drive again and return to work within 10 days after the
          surgery. Heavy activities and sport should not be undertaken for 3-4
          weeks following the surgery.
        </p>
      </TabPanel>
      <TabPanel>
        <h4>
          Following the surgery it is important to have restricted light
          activities, with no lifting or heavy duties for 10-14 days. Most
          patients may drive again and return to work within two weeks after the
          surgery. Heavy activities and sport should not be undertaken for one
          month following the surgery.
        </h4>

        <p>
          You will go home with bandages strapped around your breasts. It is
          recommended that you get a soft but supportive crop-top or sports bra.
          This will support the breasts in a comfortable healing position. We
          request that you wear this bra only for approximately 2 weeks. It
          should only be removed for showering. From two weeks post-op it may be
          removed at night for sleeping. We will provide you with oral
          medication for discomfort. Most patients do not need anything for pain
          6 to 7 days after surgery.
        </p>

        <p>
          The pain associated with Breast Augmentation surgery varies from
          person to person but in general it’s not considered to be particularly
          painful. There may be pain for the first 24 hours and discomfort for a
          week or more. We will ensure that you have adequate pain medication,
          however most patients cope extremely well with minimal reliance on
          medication.For the first few weeks after surgery, you will likely feel
          that your breasts are “too big”, because of the swelling from the
          surgery. After a few weeks, this swelling settles. You will also have
          become more accustomed to your new breast size at that time.
        </p>

        <p>
          Following surgery you will have dressings covering incision sites
          which will restrict your ability to shower in the few days following
          surgery. With some extensive procedures, a small tube may be placed in
          each breast to drain off blood and fluids for the first day or two.
        </p>
      </TabPanel>
      <TabPanel>
        <h4>
          Breast Feeding is not affected by the presence of Breast Implants.
        </h4>
        <p>
          Silicone levels do not differ significantly in the breast milk of
          women with or without Silicone Breast Implants. This is thought to be
          due to the prevalence of Silicone in the environment.
        </p>

        <p>
          There is no evidence to suggest that Silicone Breast Implants cause
          any problems in the feeding infant.
        </p>
      </TabPanel>
      <TabPanel>
        <h4>
          Whether you choose Silicone Gel Breast Implants or Saline Filled
          Breast Implants, standard mammographic views will be inadequate for
          standard breast screening.
        </h4>
        <p>
          You will need to supplement these with additional oblique views or can
          be combined with ultrasound to image the breast tissue adequately.
        </p>

        <p>
          A review of the incidences of breast cancer in women with Breast
          Implants demonstrates that there is no increased incidence of breast
          cancer in patients with Silicone Breast Implants. Ease of imaging is
          improved if the Prosthesis is placed deep to the Pectoralis major
          muscle, rather than between the muscle and the breast gland.
        </p>

        <p>
          There is no increase in mortality in women who have Breast Implants
          who develop breast cancer.
        </p>
      </TabPanel>

      <TabPanel>
        <h4>Possible risks of any surgery include:</h4>
        <ul>
          <li>Wound infection</li>
          <li>Pain and discomfort</li>
          <li>Nausea</li>
          <li>Haematoma (an accumulation of blood around the surgical site that may require drainage)</li>
          <li>Heavy bleeding from the incisions</li>
          <li>Keloid or hypertrophic scars</li>
          <li>Slow healing</li>
          <li>Separation of the wound edges</li>
          <li>Allergies to anaesthetic agents, antiseptics, suture material or dressings</li>
          <li>Deep vein thrombosis DVT (blood clot in the leg)</li>
        </ul>

        <h4>Specific breast augmentation risks:</h4>
        <ul>
          <li>Haematoma or seroma (collection of blood or fluid around the implant) that may require draining</li>
          <li>Capsular contracture: scar tissue that forms around the implant can thicken and make the implant more visible or firm and can be painful. If capsular contracture is severe, the implant may have to be replaced or removed</li>
          <li>Changes in sensation around the nipple, areolar and skin are common, but normal sensation usually returns within weeks or months</li>
          <li>Leakage or breakage of implants can occur due to injury, during mammography, capsular contraction of for no known reason</li>
          <li>Small lumps called granulomas may form in response to silicone gel that gets into breast tissue They are not cancerous but may cause concern</li>
          <li>The implant may rotate causing discomfort and distortion in breast shape</li>
          <li>The implant may extrude (push out) through the wound or the skin and become exposed</li>
          <li>Ripples appearing on the skin near the implant</li>
          <li>Rarely, breast implants have been associated with anaplastic large-cell lymphoma (ALCL), a rare form of non-Hodgkin lymphoma</li>
          <li>While the surgeon makes every effort to make the breasts look the same, differences in size, shape and symmetry may remain</li>
        </ul>
      </TabPanel>
    </StyledTabs>

    <BeforeAfter>
      <h2>Before &amp; After</h2>
      <div>
        {data.allFile.edges.map(
          (image) =>
            image.node.childImageSharp && (
              <Img resolutions={image.node.childImageSharp.resolutions} />
            )
        )}
      </div>
      <Flex justifyContent="center">
        <Link to="/gallery">
          <Button inverted={true}>See the full gallery</Button>
        </Link>
      </Flex>
    </BeforeAfter>

    <AboutContact data={data} />
  </MainLayout>
)
export const query = graphql`
  query BreastAugmentationQuery {
    allFile(filter: { relativeDirectory: { eq: "breast-augmentation" } }) {
      edges {
        node {
          childImageSharp {
            resolutions(width: 500, height: 300, toFormat: JPG) {
              ...GatsbyImageSharpResolutions
            }
          }
        }
      }
    }
    womanWaterfall: file(relativePath: { eq: "background-generic.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    otherBreast: file(relativePath: { eq: "woods-woman.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    implant: file(relativePath: { eq: "implant-place.jpg" }) {
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    implantType: file(relativePath: { eq: "implant-type.jpg" }) {
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    implantType2: file(relativePath: { eq: "cut-smooth-implant.jpg" }) {
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    implantProfile: file(relativePath: { eq: "profile.jpg" }) {
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    asaps: file(relativePath: { eq: "ASAPS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    asps: file(relativePath: { eq: "ASPS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    drSellars: file(relativePath: { eq: "dr-graham-sellars.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fracs: file(relativePath: { eq: "FRACS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
